
















































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class JnPaywall extends Vue {
  @Prop({ required: true })
  type!: string;

  @Prop({ required: false })
  message!: string;

  goToFormRichiestaProva() {
    this.$router.push('/richiesta-trial');
  }
}
