import moment from 'moment';

interface DocumentProps {
  id: string;
  seq: number;
  isFragment: boolean;
  imageSrc: string;
  title: string;
  abstract: string;
  text?: string;
  date: string;
  idAreaTematica: string;
  areaTematica: string;
  categories: string[];
  idCategory: string;
  idMateria: string;
  materia: string;
  idArgomento: string;
  argomento: string;
  tags: string[];
  author: string;
  descrAuthor: string;
  target: string;
  type: string;
  size: number;
  highlights: string[];
  index: string;
  access: number;
  since: string;
  to: string;
  sezioneCassazione: string;
  note: string[];
  abrogato: boolean;
}

export class Document {
  private props: DocumentProps;

  constructor(props: DocumentProps) {
    this.props = props;
  }

  // GETTERs and SETTERs
  get id(): string {
    return this.props.id;
  }
  get isFragment(): boolean {
    return this.props.isFragment;
  }
  get seq(): number {
    return this.props.seq;
  }
  set seq(seq: number) {
    this.props.seq = seq;
  }
  get imageSrc(): string {
    return this.props.imageSrc;
  }

  get materia(): string {
    return this.props.materia;
  }

  get argomento(): string {
    return this.props.argomento;
  }

  get title(): string {
    return this.props.title;
  }

  get sezioneCassazione(): string {
    return this.props.sezioneCassazione;
  }

  get shortTitle1(): string {
    let shortTitle = this.props.title;
    if (shortTitle.length > 170) {
      shortTitle = shortTitle.substr(0, 170) + ' ... ';
    }
    return shortTitle;
  }

  get abstract(): string {
    return this.props.abstract;
  }
  set abstract(abstract: string) {
    this.props.abstract = abstract;
  }
  get shortAbstract1(): string {
    let shortAbstract = this.props.abstract;
    if (shortAbstract.length > 600) {
      shortAbstract = shortAbstract.substring(0, 600) + ' ... ';
    }
    return shortAbstract;
  }

  get shortAbstract2(): string {
    let shortAbstract = this.props.abstract;
    if (shortAbstract.length > 400) {
      shortAbstract = shortAbstract.substring(0, 400) + ' ... ';
    }
    return shortAbstract;
  }

  getShortText(maxChars: number) {
    let shortText = this.props.text;
    let textFound = ''
    const massime = this.props.text?.split('REPUBBLICA ITALIANA')[0];
    if (massime?.toLowerCase().includes('massima n.')) {
      shortText = massime;
      textFound = 'MASSIME'
    }
    else if (this.props.text?.includes('ARGOMENTO')) {
      shortText = this.props.text.split('ARGOMENTO')[1];
      textFound = 'ARGOMENTO'
    }
    else if (this.props.text?.includes('FATTI IN CAUSA')) {
      shortText = this.props.text.split('FATTI IN CAUSA')[1];
      textFound = 'FATTI IN CAUSA'
    } else if (this.props.text?.includes('FATTI DI CAUSA')) {
      shortText = this.props.text.split('FATTI DI CAUSA')[1];
      textFound = 'FATTI DI CAUSA'
    } else if (this.props.text?.includes('FATTO E DIRITTO')) {
      shortText = this.props.text.split('FATTO E DIRITTO')[1];
      textFound = 'FATTO E DIRITTO'
    }
    else if (this.props.text?.includes('RITENUTO IN FATTO E CONSIDERATO IN DIRITTO')) {
      shortText = this.props.text.split('RITENUTO IN FATTO E CONSIDERATO IN DIRITTO')[1];
      textFound = 'RITENUTO IN FATTO E CONSIDERATO IN DIRITTO'
    } else if (this.props.text?.includes('RITENUTO IN FATTO')) {
      shortText = this.props.text.split('RITENUTO IN FATTO')[1];
      textFound = 'RITENUTO IN FATTO'
    } else if (this.props.text?.includes('RILEVATO')) {
      shortText = this.props.text.split('RILEVATO')[1];
      textFound = 'RILEVATO'
    } else if (this.props.text?.includes('RITENUTO')) {
      shortText = this.props.text.split('RITENUTO')[1];
      textFound = 'RITENUTO'
    } else if (
      this.props.text?.includes('FATTI RILEVANTI E RAGIONI DELLA DECISIONE')
    ) {
      shortText = this.props.text.split(
        'FATTI RILEVANTI E RAGIONI DELLA DECISIONE'
      )[1];
      textFound = 'FATTI RILEVANTI E RAGIONI DELLA DECISIONE'
    }
    else if (
      this.props.text?.includes('MOTIVI IN FATTO ED IN DIRITTO')
    ) {
      shortText = this.props.text.split(
        'MOTIVI IN FATTO ED IN DIRITTO'
      )[1];
      textFound = 'MOTIVI IN FATTO ED IN DIRITTO'
    }
    else if (
      this.props.text?.includes('RAGIONI IN FATTO E IN DIRITTO DELLA DECISIONE')
    ) {
      shortText = this.props.text.split(
        'RAGIONI IN FATTO E IN DIRITTO DELLA DECISIONE'
      )[1];
      textFound = 'RAGIONI IN FATTO E IN DIRITTO DELLA DECISIONE'
    }

    if (!shortText) {
      return '';
    }
    if (shortText && shortText.length > maxChars) {
      shortText = shortText.substr(0, maxChars);
    }
    const ia1 = shortText!.lastIndexOf('<a');
    const ia2 = shortText!.lastIndexOf('</a>');
    if (ia2 < ia1) {
      const remainAbstract = this.props.text!.substr(
        maxChars,
        this.props.text!.length
      );
      const ia3 = remainAbstract.indexOf('</a>');
      shortText = this.props.text!.substr(0, maxChars + ia3 + 5);
    }
    return "<b>" + textFound + "</b>" + shortText + ' ... ';
  }

  get shortText1(): string {
    return this.getShortText(200);
  }

  hasNote(): boolean {
    return this.props.note && this.props.note.length > 0;
  }

  get note(): string {
    if (!this.hasNote()) return '';
    let note = "<ol>";
    for (let i = 0; i < this.props.note.length; i++) {
      note += "<li>" + this.props.note[i] + "</li>"
    }
    return note;
  }

  get text(): string | undefined {
    if (this.props.abrogato) {
      return "<div style='background: #cccccc'>" + this.props.text + "</div>";
    }
    else {
      return this.props.text;
    }
  }

  set text(text: string | undefined) {
    this.props.text = text;
  }

  get disclaimer(): string {
    const disclaimer =
      '©' +
      moment().format('YYYY') +
      ' - Juranet s.r.l - Riproduzione Riservata';
    return (
      "<br/><br/><hr class='solid'><div style='text-align: center;'>" +
      disclaimer +
      '</div></hr>'
    );
  }

  get textWithDisclaimer(): string | undefined {
    let text = this.props.text?.replaceAll("<body>", "")?.replaceAll("</body>", "").replaceAll("<html>", "").replaceAll("</html>", "")
    text = text?.replace("<head>", "<head><meta http-equiv=\"Content-Type\" content=\"text/html; charset=UTF-8\">")
    return "<!DOCTYPE html><body>" + this.props.abstract + text + this.disclaimer + "</html></body>";
  }

  get date(): string {
    return this.props.date;
  }

  get dateFormatted(): string {
    return moment(this.props.date, 'YYYY-MM-DD')
      .locale('it')
      .format('DD MMMM YYYY');
  }

  get dateFormattedExtended(): string {
    const dateFormattedExtended = moment(this.props.date, 'YYYY-MM-DD')
      .locale('it')
      .format('dddd, DD MMMM YYYY');
    return (
      dateFormattedExtended.charAt(0).toUpperCase() +
      dateFormattedExtended.slice(1)
    );
  }

  get categories(): string[] {
    return this.props.categories;
  }

  get idCategory(): string {
    return this.props.idCategory;
  }

  get idMateria(): string {
    return this.props.idMateria;
  }

  get idArgomento(): string {
    return this.props.idArgomento;
  }

  get idAreaTematica(): string {
    return this.props.idAreaTematica;
  }

  get author(): string {
    return this.props.author;
  }

  get descrAuthor(): string {
    return this.props.descrAuthor;
  }

  get tags(): string[] {
    return this.props.tags;
  }

  get target(): string {
    return this.props.target;
  }

  get type(): string {
    return this.props.type;
  }
  get access(): number {
    return this.props.access;
  }
  get size(): number {
    return this.props.size;
  }
  get highlights(): string[] {
    return this.props.highlights;
  }
  get index(): string {
    return this.props.index;
  }
  get url(): string {
    return window.location.href;
  }
  get since(): string {
    return this.props.since;
  }
  get to(): string {
    return this.props.to;
  }
  get abrogato(): boolean {
    return this.props.abrogato;
  }
}
