import { Document } from "@/models/document.model";
import { Category } from "@/models/category.model";
import { Service } from "@/models/service.model";
import { Event } from "@/models/event.model";
import { Profile } from "@/models/profile.model";
import { SearchData } from "@/models/search.model";
import { Picklist } from "@/models/picklist.model";
import { Utils } from "@/services/utils";

import axios from "axios";
import moment from "moment";

class ElasticSearchService {
  constructor() {
    console.log("Creating a new instance of EsService...");
  }

  async countDocs(searchData: SearchData) {
    if (!searchData) {
      return 0;
    }
    const url = process.env.VUE_APP_JURANEWS_ES_SERVICES_URL + "/count";
    const res = await axios.post(url, {
      headers: { apikey: process.env.VUE_APP_API_KEY },
      data: searchData.properties,
    });
    if (res.status === 200) {
      return res.data.body.count;
    }
  }

  async getDoc(searchData: SearchData) {
    const res = await axios.post(
      process.env.VUE_APP_JURANEWS_ES_SERVICES_URL + "/search",
      {
        headers: { apikey: process.env.VUE_APP_API_KEY },
        data: searchData.properties,
      }
    );
    const hit = res.data.body.hits.hits[0];
    if (res.status === 200 && hit) {
      const isFragment = searchData.target != undefined;
      const s = res.data.body.hits.hits[0]._source;
      let text = undefined;
      if (s.text) {
        text = Utils.b64DecodeUnicode(s.text);
      }

      return new Document({
        id: s.id,
        seq: 0,
        isFragment: isFragment,
        target: searchData.target ? searchData.target : "",
        type: searchData.type ? searchData.type : "",
        size: s.attachment.content_length,
        imageSrc: Utils.getImage(s),
        title: s.title,
        abstract: s.introText,
        materia: s.materia,
        argomento: s.argomento,
        text: text,
        tags: s.tags,
        date: s.dataCreation,
        categories: s.categories,
        idCategory: s.categoryId,
        idAreaTematica: s.idAreaTematica,
        areaTematica: s.areaTematica,
        idMateria: s.idMateria,
        idArgomento: s.idArgomento,
        author: s.autore,
        descrAuthor: s.descrAutore,
        highlights: [],
        index: hit._index,
        access: s.access,
        since: s.since,
        to: s.to,
        sezioneCassazione: s.sezioneCassazione,
        abrogato: s.abrogato,
        note: s.note,
      });
    }
    return undefined;
  }

  async getDocs(searchData: SearchData) {
    const docs: Document[] = [];
    const url = process.env.VUE_APP_JURANEWS_ES_SERVICES_URL + "/search";
    const res = await axios.post(url, {
      headers: { apikey: process.env.VUE_APP_API_KEY },
      data: searchData.properties,
    });
    let seq = 0;
    const hit = res.data.body.hits.hits[0];
    if (res.status === 200 && hit) {
      for (const val of res.data.body.hits.hits) {
        try {
          const isFragment = searchData.target != undefined;
          const s = val._source;
          const highlights = [];
          if (val.highlight) {
            const hihlight = val.highlight["attachment.content"];
            if (hihlight) {
              for (let i = 0; i < hihlight.length; i++) {
                highlights.push("- " + hihlight[i]);
              }
            }
          }
          let text = undefined;
          if (s.text) {
            text = Utils.b64DecodeUnicode(s.text);
          }
          docs.push(
            new Document({
              id: s.id,
              seq: seq,
              isFragment: isFragment,
              target: searchData.target ? searchData.target : "",
              type: searchData.type ? searchData.type : "",
              size: s.attachment.content_lenght,
              imageSrc: Utils.getImage(s),
              title: s.title,
              abstract: s.introText,
              materia: s.materia,
              argomento: s.argomento,
              text: text,
              tags: s.tags,
              date: s.dataCreation,
              categories: s.categories,
              idCategory: s.categoryId,
              idAreaTematica: s.idAreaTematica,
              areaTematica: s.areaTematica,
              idMateria: s.idMateria,
              idArgomento: s.idArgomento,
              author: s.autore,
              descrAuthor: s.descrAutore,
              highlights: highlights,
              index: val._index,
              access: s.access,
              since: s.since,
              to: s.to,
              sezioneCassazione: s.tipoSentenza,
              abrogato: s.abrogato,
              note: s.note,
            })
          );
          seq++;
        } catch (err) {
          console.error(err);
        }
      }
    }
    return docs;
  }

  async getEvents() {
    const eventsRaw = JSON.parse(await this.getOptions("eventi"));
    const events: Event[] = [];
    for (const e of eventsRaw) {
      let startDate = undefined;
      let endDate = undefined;
      if (moment(e.startDate).isSame(moment(e.endDate))) {
        startDate = moment(e.startDate).format("YYYY-MM-DD");
        endDate = startDate;
      } else {
        startDate = e.startDate;
        endDate = e.endDate;
      }
      let color = "#";
      if (e.idCategory == 1) {
        color = "#910302";
      } else if (e.idCategory == 2) {
        color = "#110595";
      } else if (e.idCategory == 3) {
        color = "#096407";
      } else {
        color = "grey";
      }
      events.push(
        new Event({
          id: e.id,
          //name: e.category,
          name: e.title,
          title: e.title,
          start: startDate!,
          end: endDate!,
          idCategory: e.idCategory,
          shortDesc: e.shortDesc,
          desc: e.desc,
          color: color,
          category: e.category,
        })
      );
    }
    return events;
  }

  async getStructure(searchData: SearchData) {
    const res = await axios.post(
      process.env.VUE_APP_JURANEWS_ES_SERVICES_URL + "/structure",
      {
        headers: { apikey: process.env.VUE_APP_API_KEY },
        data: searchData.properties,
      }
    );
    if (res.status === 200) {
      return res.data.body;
    }
  }

  async getChiSiamoFirstStrip() {
    return new Document({
      id: "1",
      seq: 1,
      target: "",
      type: "",
      size: 0,
      isFragment: false,
      imageSrc: "/img/chi-siamo/first-strip.jpg",
      materia: "Materia",
      argomento: "Argomento",
      title: "Sed a magna non sem porta sagittis a sed area.",
      abstract: "",
      text: "JuraNews nasce da un'importante esperienza editoriale che ha permesso in forma naturale l'evoluzione in sistema informativo per i professionisti del mercato legale. Documentazione, prima lettura, analisi ed interpretazione trovano in un’unica piattaforma integrata lo strumento ideale per un mercato sempre più esigente e raffinato che seleziona bisogni ed informazioni.<br/><br/><b>Dalla raccolta sistematica, organizzata e capillare delle fonti fino ad uno strumento editoriale innovativo</b><br/><br/>Juranet, nasce nel 1999 per perseguire un progetto di raccolta di tutte le norme vigenti in Italia, di prassi e di giurisprudenza, e successivamente si attiva per realizzare un sistema informativo giuridico di riferimento. I testi Juranet, aggiornati quotidianamente, sono caratterizzati dal massimo grado di affidabilità.",
      tags: [],
      date: "2021-03-02",
      categories: ["Category"],
      idCategory: "",
      idAreaTematica: "",
      areaTematica: "",
      idMateria: "",
      idArgomento: "",
      author: "",
      descrAuthor: "",
      highlights: [],
      index: "juranews",
      access: 1,
      since: "",
      to: "",
      sezioneCassazione: "",
      abrogato: false,
      note: [],
    });
  }

  async getChiSiamoSecondStrip() {
    return new Document({
      id: "1",
      seq: 1,
      target: "",
      type: "",
      size: 0,
      isFragment: false,
      imageSrc: "/img/chi-siamo/second-strip.png",
      materia: "Materia",
      argomento: "Argomento",
      title: "Sed a magna non sem porta sagittis a sed area.",
      abstract:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer ullamcorper, libero sed fringilla interdum, nibh dolor rhoncus mauris, eget commodo justo neque eu mauris.",
      text: "Juranet, nel 2020 si evolve in Juranews.<br/><br/>Documentazione, sintesi d’autore e chiavi interpretative: ecco gli elementi distintivi dell’offerta di JuraNews. In un'unica soluzione editoriale facile da consultare e costruita sui bisogni professionali reali.<br/><br/>Redazioni dedicate alla raccolta, classificazione e sistematizzazione del materiale documentale in un confronto costante e sistematico con un gruppo esclusivo di Autori che possano individuare le tematiche di maggior attualità e gli orientamenti consolidati in giurisprudenza.<br/><br/>Juranews vuole offrire soluzioni immediate ed un punto di vista “privilegiato” che esalti le competenze del professionista e gli permetta di offrire un servizio consulenziale affidabile e distintivo.",
      tags: [],
      date: "2021-03-02",
      categories: ["Category"],
      idCategory: "",
      idAreaTematica: "",
      areaTematica: "",
      idMateria: "",
      idArgomento: "",
      author: "",
      descrAuthor: "",
      highlights: [],
      index: "juranews",
      access: 1,
      since: "",
      to: "",
      sezioneCassazione: "",
      abrogato: false,
      note: [],
    });
  }

  async getChiSiamoThirdStrip(index: number) {
    if (index == 1) {
      return new Document({
        id: "1",
        seq: 1,
        target: "",
        type: "",
        size: 0,
        isFragment: false,
        imageSrc: "",
        materia: "Materia",
        argomento: "Argomento",
        title: "Redazione Juranet",
        abstract: "",
        tags: [],
        date: "2021-03-02",
        categories: ["Category"],
        idCategory: "",
        idAreaTematica: "",
        areaTematica: "",
        idMateria: "",
        idArgomento: "",
        author: "",
        descrAuthor: "",
        highlights: [],
        index: "juranews",
        access: 1,
        since: "",
        to: "",
        sezioneCassazione: "",
        text: "La Redazione è impegnata nell’aggiornamento costante della Banca Dati Juranet, garantendo l’immediata disponibilità del dato e della sua affidabilità, grazie ad una meticolosa selezione dei documenti che si avvale di un costante monitoraggio dei siti istituzionali, consentendo così una rapida pubblicazione di norme, prassi e giurisprudenza, con tutte le annotazioni che mettono in risalto le modifiche e le versioni vigenti. L’alta professionalità della Redazione Juranet consente di aggiornare quotidianamente i documenti, apponendo i link e le annotazioni ai riferimenti normativi, e fornendo il formato testuale in tempi brevissimi. In tal modo il Professionista potrà contare su risposte immediate e sicure. A ciò si aggiunge la possibità di individuare le novità in sintesi visibili sin da subito nella Home Page di JuraNews.",
        abrogato: false,
        note: [],
      });
    } else if (index == 2) {
      return new Document({
        id: "1",
        seq: 1,
        target: "",
        type: "",
        size: 0,
        isFragment: false,
        imageSrc: "",
        materia: "Materia",
        argomento: "Argomento",
        title: "Fondazione AIGA Tommaso Bucciarelli",
        abstract: "",
        tags: [],
        date: "2021-03-02",
        categories: ["Category"],
        idCategory: "",
        idAreaTematica: "",
        areaTematica: "",
        idMateria: "",
        idArgomento: "",
        author: "",
        descrAuthor: "",
        highlights: [],
        index: "juranews",
        access: 1,
        since: "",
        to: "",
        sezioneCassazione: "",
        text: "La Fondazione A.I.G.A. Tommaso Bucciarelli, munita di personalità giuridica, apolitica, aconfessionale e senza scopo di lucro, è stata costituita in onore di Tommaso Bucciarelli – Avvocato tra gli ideatori e promotori dell’ A.I.G.A. “Associazione Italiana Giovani Avvocati” – con lo scopo di promuovere la formazione degli Avvocati, il loro aggiornamento culturale e professionale, con particolare attenzione alle giovani generazioni di professionisti, anche attraverso la creazione di scuole per l’accesso alla professione forense e la collaborazione alle attività delle scuole forensi già esistenti.",
        abrogato: false,
        note: [],
      });
    } else if (index == 3) {
      return new Document({
        id: "1",
        seq: 1,
        target: "",
        type: "",
        size: 0,
        isFragment: false,
        imageSrc: "",
        materia: "Materia",
        argomento: "Argomento",
        title: "Studio Ceroli, Marchegiani, Natalucci, Pucci",
        abstract: "",
        tags: [],
        date: "2021-03-02",
        categories: ["Category"],
        idCategory: "",
        idAreaTematica: "",
        areaTematica: "",
        idMateria: "",
        idArgomento: "",
        author: "",
        descrAuthor: "",
        highlights: [],
        index: "juranews",
        access: 1,
        since: "",
        to: "",
        sezioneCassazione: "",
        text: "Lo Studio è una Associazione Professionale di Dottori Commercialisti, Giornalisti Pubblicisti e Consulenti del Lavoro che, negli anni ha sviluppato importanti competenze in ambito tributario e societario, incentrando le proprie attività nelle aree di consulenza Aziendale, Tributaria, Societaria, Lavoro. Le professionalità interne si occupano, inoltre, di formazione, impegnandosi in qualità di relatori sia nei principali convegni in ambito nazionale, sia nella formazione on site su tematiche specifiche; collaborano altresì, da oltre venti anni, con le principali riviste e quotidiani di settore in materia fiscale, societaria e del lavoro con la redazione di articoli, focus di approfondimento e redazione di libri.",
        abrogato: false,
        note: [],
      });
    } else if (index == 4) {
      return new Document({
        id: "1",
        seq: 1,
        target: "",
        type: "",
        size: 0,
        isFragment: false,
        imageSrc: "",
        materia: "Materia",
        argomento: "Argomento",
        title: "Pirola Pennuto Zei & Associati",
        abstract: "",
        tags: [],
        date: "2021-03-02",
        categories: ["Category"],
        idCategory: "",
        idAreaTematica: "",
        areaTematica: "",
        idMateria: "",
        idArgomento: "",
        author: "",
        descrAuthor: "",
        highlights: [],
        index: "juranews",
        access: 1,
        since: "",
        to: "",
        sezioneCassazione: "",
        text: "È uno Studio legale e tributario indipendente tra i Leader del mercato in Italia. Lo studio conta oltre 600 professionisti che integrano competenze tecniche e specialistiche, con l’obiettivo di garantire una vasta tipologia di servizi nelle discipline legali, societarie e fiscali, in ambito sia nazionale sia internazionale, dove opera avvalendosi, oltre che dei propri uffici esteri, anche di una fitta rete di corrispondenti. Lo Studio attraverso l’utilizzo di metodologie avanzate e un approccio multidisciplinare, si pone quale autorevole partner della business community, del mondo accademico e professionale in un’ottica di sinergia, supporto e arricchimento reciproco.",
        abrogato: false,
        note: [],
      });
    } else if (index == 5) {
      return new Document({
        id: "1",
        seq: 1,
        target: "",
        type: "",
        size: 0,
        isFragment: false,
        imageSrc: "",
        materia: "Materia",
        argomento: "Argomento",
        title: "SPRINT Soluzioni Editoriali",
        abstract: "",
        tags: [],
        date: "2021-03-02",
        categories: ["Category"],
        idCategory: "",
        idAreaTematica: "",
        areaTematica: "",
        idMateria: "",
        idArgomento: "",
        author: "",
        descrAuthor: "",
        highlights: [],
        index: "juranews",
        access: 1,
        since: "",
        to: "",
        sezioneCassazione: "",
        text: "L'azienda è una start-up innovativa, che si muove nel campo dell’editoria e servizi digitali editoriali in ambito giuridico, per la formazione e l’aggiornamento professionale ed universitario. Oltre a produrre contenuti editoriali accurati e tempestivi, grazie alla propria redazione e ad una rete di professionisti esperti, SPRINT Soluzioni Editoriali si contraddistingue per la sua capacità di realizzare strumenti digitali innovativi - forte di un know-how pluridecennale - pienamente in linea con le esigenze della moderna editoria professionale.",
        abrogato: false,
        note: [],
      });
    } else if (index == 6) {
      return new Document({
        id: "1",
        seq: 1,
        target: "",
        type: "",
        size: 0,
        isFragment: false,
        imageSrc: "",
        materia: "Materia",
        argomento: "Argomento",
        title: "Pr. Ref. Calogero Commandatore",
        abstract: "",
        tags: [],
        date: "2021-03-02",
        categories: ["Category"],
        idCategory: "",
        idAreaTematica: "",
        areaTematica: "",
        idMateria: "",
        idArgomento: "",
        author: "",
        descrAuthor: "",
        highlights: [],
        index: "juranews",
        access: 1,
        since: "",
        to: "",
        sezioneCassazione: "",
        text: "Attualmente magistrato T.A.R. è stato giudice ordinario, svolgendo le funzioni di giudice penale, giudice civile e delle procedure concorsuali. Dottore di ricerca in diritto amministrativo e cultore della materia di 'diritto amministrativo italiano ed europeo' presso l'Università degli Studi di Catania. Docente presso la SSPL della Sicilia Centrale. Autore di numerosi articoli in riviste specializzate. è componente del comitato scientifico della Rivista Giuridica AmbienteDiritto.it.",
        abrogato: false,
        note: [],
      });
    } else if (index == 7) {
      return new Document({
        id: "1",
        seq: 1,
        target: "",
        type: "",
        size: 0,
        isFragment: false,
        imageSrc: "",
        materia: "Materia",
        argomento: "Argomento",
        title: "Ref. Luca Pavia",
        abstract: "",
        tags: [],
        date: "2021-03-02",
        categories: ["Category"],
        idCategory: "",
        idAreaTematica: "",
        areaTematica: "",
        idMateria: "",
        idArgomento: "",
        author: "",
        descrAuthor: "",
        highlights: [],
        index: "juranews",
        access: 1,
        since: "",
        to: "",
        sezioneCassazione: "",
        text: "Attualmente magistrato T.A.R., è stato prima funzionario e poi Dirigente della Polizia di Stato. Laureto con lode presso l'Università degli studi di Torino, ha successivamente conseguito l'abilitazione all'esercizio delle professioni forensi e due master, uno un diritto penale europeo presso l'Università degli studi di Torino e uno in scienze della sicurezza, presso l'università 'La Sapienza' di Roma.",
        abrogato: false,
        note: [],
      });
    } else if (index == 8) {
      return new Document({
        id: "1",
        seq: 1,
        target: "",
        type: "",
        size: 0,
        isFragment: false,
        imageSrc: "",
        materia: "Materia",
        argomento: "Argomento",
        title: "Avv. Mariangela Di Biase",
        abstract: "",
        tags: [],
        date: "2021-03-02",
        categories: ["Category"],
        idCategory: "",
        idAreaTematica: "",
        areaTematica: "",
        idMateria: "",
        idArgomento: "",
        author: "",
        descrAuthor: "",
        highlights: [],
        index: "juranews",
        access: 1,
        since: "",
        to: "",
        sezioneCassazione: "",
        text: "Avvocato penalista, iscritta all’Ordine degli Avvocati di Campobasso. Esercita la professione occupandosi prevalentemente di contenzioso penale e consulenza e gestione di pratiche nel campo del diritto delle nuove tecnologie, con particolare riferimento al sistema blockchain e della sicurezza aziendale, tokenizzazione aziendale, criptovalute, NFT, Metaverso, DeFi, sistema Kyc e quantum computing. Autrice di numerose pubblicazioni e di articoli in materia penale e procedura penale, e diritto delle nuove tecnologie su Juranews di cui è responsabile del comitato tecnico scientifico e autore. Componente della Giunta Nazionale AIGA. Responsabile del comitato tecnico scientifico Juranews.",
        abrogato: false,
        note: [],
      });
    } else {
      return new Document({
        id: "1",
        seq: 1,
        target: "",
        type: "",
        size: 0,
        isFragment: false,
        imageSrc: "",
        materia: "Materia",
        argomento: "Argomento",
        title: "Avv. Elisa Brino",
        abstract: "",
        tags: [],
        date: "2021-03-02",
        categories: ["Category"],
        idCategory: "",
        idAreaTematica: "",
        areaTematica: "",
        idMateria: "",
        idArgomento: "",
        author: "",
        descrAuthor: "",
        highlights: [],
        index: "juranews",
        access: 1,
        since: "",
        to: "",
        sezioneCassazione: "",
        text: "Avvocato civilista, iscritta all’Albo degli Avvocati di Torino. Si occupa in via prevalente di assistenza e consulenza nell’ambito del diritto della persona, della famiglia e dei minori, anche con riferimento alla tutela delle donne vittime di violenza di genere, nonché delle successioni e della protezione delle persone prive di autonomia.",
        abrogato: false,
        note: [],
      });
    }
  }

  async getServicesInQuickAccessStrip(searchData: SearchData) {
    const services: Service[] = [];
    services.push(
      new Service({
        id: "1",
        seq: 1,
        imageSrc:
          window.location.origin + "/img/home/sintesi-giurisprudenziali.jpg",
        title: "Sintesi Giurisprudenziali e Schede Autorali",
        description: "",
        routePath: "Category",
      })
    );
    services.push(
      new Service({
        id: "2",
        seq: 2,
        imageSrc: window.location.origin + "/img/home/richieste-on-demand.jpg",
        title: "Ricerche on demand",
        description: "",
        routePath: "Category",
      })
    );
    services.push(
      new Service({
        id: "3",
        seq: 3,
        imageSrc: window.location.origin + "/img/home/biblioteca.jpg",
        title: "Biblioteca",
        description: "",
        routePath: "Category",
      })
    );
    services.push(
      new Service({
        id: "4",
        seq: 4,
        imageSrc: window.location.origin + "/img/home/press.jpg",
        title: "Press - Dicono di noi",
        description: "",
        routePath: "Category",
      })
    );
    return services;
  }

  async getProfiles(searchData: SearchData) {
    const profiles: Profile[] = [];
    profiles.push(
      new Profile({
        id: "1",
        seq: 1,
        imageSrc:
          "https://images.pexels.com/photos/5668473/pexels-photo-5668473.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
        name: "Mario Rossi",
        businessRole: "CEO",
        curriculum:
          "Etiam congue id nisl ac interdum. Quiscue urna metus, sclererisque...",
        routePath: "Profile",
      })
    );
    profiles.push(
      new Profile({
        id: "2",
        seq: 2,
        imageSrc:
          "https://images.pexels.com/photos/5668473/pexels-photo-5668473.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
        name: "Mario Rossi",
        businessRole: "CFO",
        curriculum:
          "Etiam congue id nisl ac interdum. Quiscue urna metus, sclererisque...",
        routePath: "Profile",
      })
    );
    profiles.push(
      new Profile({
        id: "3",
        seq: 3,
        imageSrc:
          "https://images.pexels.com/photos/5668473/pexels-photo-5668473.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
        name: "Mario Rossi",
        businessRole: "VP",
        curriculum:
          "Etiam congue id nisl ac interdum. Quiscue urna metus, sclererisque...",
        routePath: "Profile",
      })
    );
    profiles.push(
      new Profile({
        id: "4",
        seq: 4,
        imageSrc:
          "https://images.pexels.com/photos/5668473/pexels-photo-5668473.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
        name: "Mario Rossi",
        businessRole: "CCO",
        curriculum:
          "Etiam congue id nisl ac interdum. Quiscue urna metus, sclererisque...",
        routePath: "Profile",
      })
    );
    profiles.push(
      new Profile({
        id: "5",
        seq: 5,
        imageSrc:
          "https://images.pexels.com/photos/5668473/pexels-photo-5668473.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
        name: "Mario Rossi",
        businessRole: "QC",
        curriculum:
          "Etiam congue id nisl ac interdum. Quiscue urna metus, sclererisque...",
        routePath: "Profile",
      })
    );
    profiles.push(
      new Profile({
        id: "6",
        seq: 6,
        imageSrc:
          "https://images.pexels.com/photos/5668473/pexels-photo-5668473.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
        name: "Mario Rossi",
        businessRole: "HR",
        curriculum:
          "Etiam congue id nisl ac interdum. Quiscue urna metus, sclererisque...",
        routePath: "Profile",
      })
    );
    profiles.push(
      new Profile({
        id: "7",
        seq: 7,
        imageSrc:
          "https://images.pexels.com/photos/5668473/pexels-photo-5668473.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
        name: "Mario Rossi",
        businessRole: "HR",
        curriculum:
          "Etiam congue id nisl ac interdum. Quiscue urna metus, sclererisque...",
        routePath: "Profile",
      })
    );
    profiles.push(
      new Profile({
        id: "8",
        seq: 8,
        imageSrc:
          "https://images.pexels.com/photos/5668473/pexels-photo-5668473.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
        name: "Mario Rossi",
        businessRole: "PR",
        curriculum:
          "Etiam congue id nisl ac interdum. Quiscue urna metus, sclererisque...",
        routePath: "Profile",
      })
    );

    return profiles;
  }

  async getPicklist(name: string) {
    const picklist: Picklist[] = [];
    const res = await axios.get(
      process.env.VUE_APP_JURANEWS_ES_SERVICES_URL +
        "/options?index=juranews-options&name=" +
        name,
      { headers: { apikey: process.env.VUE_APP_API_KEY } }
    );
    if (res.status === 200) {
      const options = JSON.parse(res.data.body.hits.hits[0]._source.options);
      for (const opt of options) {
        picklist.push(new Picklist({ value: opt.id, label: opt.value }));
      }
    }
    return picklist;
  }

  async getOptions(name: string) {
    const res = await axios.get(
      process.env.VUE_APP_JURANEWS_ES_SERVICES_URL +
        "/options?index=juranews-options&name=" +
        name,
      { headers: { apikey: process.env.VUE_APP_API_KEY } }
    );
    if (res.status === 200) {
      return res.data.body.hits.hits[0]._source.options;
    }
  }
}
export const EsService = new ElasticSearchService();
