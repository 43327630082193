var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('jn-progress',{attrs:{"loading":_vm.loading}}),(!_vm.success)?_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"append-icon":_vm.showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off',"label":"Password attuale","type":_vm.showCurrentPassword ? 'text' : 'password',"error-messages":errors,"loading":"","dark":""},on:{"click:append":function($event){_vm.showCurrentPassword = !_vm.showCurrentPassword}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"value":_vm.progressCurrentPassword,"color":_vm.colorCurrentPassword,"absolute":"","height":"6"}})]},proxy:true}],null,true),model:{value:(_vm.currentPassword),callback:function ($$v) {_vm.currentPassword=$$v},expression:"currentPassword"}},'v-text-field',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v("La password deve contenere almeno 8 caratteri alfanumerici, di cui almeno uno maiuscolo e un numero.")])])]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"append-icon":_vm.showNewPassword ? 'mdi-eye' : 'mdi-eye-off',"label":"Nuova password","type":_vm.showNewPassword ? 'text' : 'password',"error-messages":errors,"loading":"","dark":""},on:{"click:append":function($event){_vm.showNewPassword = !_vm.showNewPassword}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"value":_vm.progressNewPassword,"color":_vm.colorNewPassword,"absolute":"","height":"6"}})]},proxy:true}],null,true),model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}},'v-text-field',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v("La password deve contenere almeno 8 caratteri alfanumerici, di cui almeno uno maiuscolo e un numero.")])])]}}],null,true)})],1),_c('v-col',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"append-icon":_vm.showNewPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off',"label":"Conferma nuova password","type":_vm.showNewPasswordConfirm ? 'text' : 'password',"error-messages":errors,"loading":"","dark":""},on:{"click:append":function($event){_vm.showNewPasswordConfirm = !_vm.showNewPasswordConfirm}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"value":_vm.progressNewPasswordConfirm,"color":_vm.colorNewPasswordConfirm,"absolute":"","height":"6"}})]},proxy:true}],null,true),model:{value:(_vm.newPasswordConfirm),callback:function ($$v) {_vm.newPasswordConfirm=$$v},expression:"newPasswordConfirm"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"red-juranews elevation-0 rounded-0",attrs:{"dark":"","text":"","disabled":invalid || !_vm.passwordOk},on:{"click":_vm.reset}},[_vm._v(" CONFERMA ")])],1)],1)],1)]}}],null,false,2011593742)}):_vm._e(),_c('v-snackbar',{attrs:{"timeout":_vm.alertTimeout,"top":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({staticClass:"red-juranews white--text grey darken-1",on:{"click":function($event){_vm.alert = false}}},'v-btn',attrs,false),[_vm._v(" CHIUDI ")])]}}]),model:{value:(_vm.alert),callback:function ($$v) {_vm.alert=$$v},expression:"alert"}},[_vm._v(" "+_vm._s(_vm.alertMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }