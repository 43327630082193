




































import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class JnWhatsAppContactMenu extends Vue {
  menu = false;

  whatsAppNumber = process.env.VUE_APP_WHATS_APP_NUMBER;
}
