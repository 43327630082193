
























import { Component, Prop, Vue } from "vue-property-decorator";
import { Menu } from "@/models/menu.model";

@Component({
  components: {},
})
export default class JnSubMenuItem extends Vue {
  @Prop({ required: true, default: "" })
  item!: Menu;

  menu = false;

  goTo() {
    this.menu = false;
    this.$router.push(this.item.routePath);
  }
}
