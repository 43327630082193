










































import { Component, Prop, Vue } from "vue-property-decorator";
import { Document } from "@/models/document.model";
import { SearchData } from "@/models/search.model";
import EventBus from "@/services/event.bus";
import { EsService } from "@/services/es.service";
import JnProgress from "@/views/common/Progress.vue";
import { CategoriesGiurisprudenza } from "@/models/enums.model";

@Component({
  components: { JnProgress },
})
export default class JnSearchResultElement extends Vue {
  /*@Prop({ required: true })
  searchData!: SearchData;*/

  @Prop({ required: true })
  doc!: Document;

  loading = false;

  abstract = "";

  view() {
    EventBus.$emit("view-article");
    let routeData = undefined;
    if (this.doc.index == "juranews") {
      routeData = this.$router.resolve({
        name: "JuranewsDoc",
        params: { id: this.doc.id },
      });
    } else {
      routeData = this.$router.resolve({
        name: "JuranetDoc",
        params: { id: this.doc.id },
      });
    }

    let keywords = undefined
    const searchData = this.$store.getters.searchData;
    if (searchData) {
      keywords = searchData.keywords;
      if (keywords && keywords.length > 0) {
        keywords = keywords.join("§§");
      }
    }
    console.log(keywords)
    if (keywords) {
      window.open(routeData.href + "?keywords=" + keywords + "&queryType=" + searchData.queryType, "_blank");
    } else {
      window.open(routeData.href, "_blank");
    }
  }

  showViewAbstract() {
    return (
      this.doc.index.includes("juranet") &&
      CategoriesGiurisprudenza.includes(String(this.doc.idCategory))
    );
  }


  async loadAbstract() {
    const filteredArray = CategoriesGiurisprudenza.filter(
      (value) => value == this.doc.idCategory
    );
    if (this.doc.index.includes("juranet") && filteredArray.length > 0) {
      this.loading = true;
      const searchData = new SearchData({
        index: process.env.VUE_APP_JURANET_INDEXES!,
        id: this.doc.id,
        size: 1,
        from: 0,
        withText: true,
      });
      if (!this.doc.text) {
        this.doc!.text = (await EsService.getDoc(searchData))!.text;
        this.abstract = this.doc.getShortText(2000);
      }
      this.loading = false;
    }
  }

}
