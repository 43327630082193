
























































































































































import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment';
@Component({
  components: {},
})
export default class JnAppFooter extends Vue {
  email = '';
  message = '';

  get year() {
    return moment().format('YYYY');
  }
}
