























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Menu } from '@/models/menu.model';
import JnSubMenuItem from '@/views/home/SubMenuItem.vue';
import EventBus from '@/services/event.bus';

@Component({
  components: { JnSubMenuItem },
})
export default class JnMenuItem extends Vue {
  @Prop({ required: true, default: '' })
  item!: Menu;

  menu = false;
  menuSelected = '';

  goTo() {
    try {
      console.log('MenuItem.goTo: ' + this.item.routePath);
      EventBus.$emit('reset-selection', this.item);
      if (!this.item.items) {
        this.$router.push(this.item.routePath);
      }
    } catch (err) {
      console.log('MenuItem.goTo(err):  ' + JSON.stringify(err));
    }
  }

  goToSub(item: any) {
    EventBus.$emit('reset-selection', this.item);
    this.$router.push(item.routePath);
  }

  created() {
    EventBus.$on('reset-selection', (item: Menu) => {
      if (!this.$route.path.startsWith(item.routePath)) {
        this.menuSelected = '';
      }
    });
  }
}
