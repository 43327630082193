







































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { JnService } from '@/services/jn.service';
import { CrmInfo } from '@/models/crm/crmInfo.model';
import { ValidationObserver } from 'vee-validate';
import JnProgress from '@/views/common/Progress.vue';
import EventBus from '@/services/event.bus';

@Component({
  components: { ValidationObserver, JnProgress },
})
export default class JnResetPassword extends Vue {
  @Prop({ required: true })
  username?: string;

  success = false;

  currentPassword = '';
  currentPasswordProgress = 0;
  currentPasswordConfirm = '';
  newPassword = '';
  newPasswordProgress = 0;
  newPasswordConfirm = '';
  newPasswordConfirmProgress = 0;
  showCurrentPassword = false;
  showNewPassword = false;
  showNewPasswordConfirm = false;
  regExPassword = /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;

  alert = false;
  alertMessage = '';
  alertTimeout = 6000;
  loading = false;

  progress(password: string, passwordProgress: number) {
    passwordProgress = Math.min(80, password.length * 10);
    if (this.regExPassword.test(password)) {
      passwordProgress = passwordProgress + 20;
    }
    return passwordProgress;
  }

  get progressCurrentPassword(): number {
    this.currentPasswordProgress = this.progress(
      this.currentPassword,
      this.currentPasswordProgress
    );
    return this.currentPasswordProgress;
  }

  get progressNewPassword(): number {
    this.newPasswordProgress = this.progress(
      this.newPassword,
      this.newPasswordProgress
    );
    return this.newPasswordProgress;
  }

  get progressNewPasswordConfirm(): number {
    this.newPasswordConfirmProgress = this.progress(
      this.newPasswordConfirm,
      this.newPasswordConfirmProgress
    );
    return this.newPasswordConfirmProgress;
  }

  get colorCurrentPassword() {
    if (this.currentPasswordProgress < 80) {
      return 'error';
    } else if (
      this.currentPasswordProgress >= 80 &&
      this.currentPasswordProgress < 100
    ) {
      return 'warning';
    } else {
      return 'success';
    }
  }

  get colorNewPassword() {
    if (this.newPasswordProgress < 80) {
      return 'error';
    } else if (
      this.newPasswordProgress >= 80 &&
      this.newPasswordProgress < 100
    ) {
      return 'warning';
    } else {
      return 'success';
    }
  }

  get passwordMatching(): boolean {
    return this.newPassword == this.newPasswordConfirm;
  }

  get colorNewPasswordConfirm() {
    if (!this.passwordOk) {
      return 'error';
    } else {
      return 'success';
    }
  }

  get passwordOk(): boolean {
    return this.passwordMatching && this.passwordFormatOk;
  }

  get passwordFormatOk() {
    return (
      this.regExPassword.test(this.newPassword) && this.newPassword.length >= 8
    );
  }

  get authenticated() {
    return this.$store.getters.crmInfo;
  }

  async reset() {
    this.loading = true;
    //const username = String(this.$cookies.get('username'));
    if (this.username) {
      const res = await JnService.changePassword(
        this.username,
        this.currentPassword,
        this.newPassword
      );
      if (res.code == 200) {
        const user = await JnService.getCrmUser(this.username, undefined);
        const purchaseOrder = await JnService.getActivePurchaseOrder(user.id);
        this.$cookies.set('username', this.username, { expires: process.env.VUE_APP_SESSION_TIMEOUT + 'h' });
        //sessionStorage.setItem('username', username);
        if (purchaseOrder) {
          sessionStorage.setItem('serviceId', purchaseOrder.serviceId);
        }
        sessionStorage.removeItem('changeRequired');
        EventBus.$emit('reset-password');
        this.success = true;
      } else {
        this.alert = true;
        this.alertMessage = res.data;
      }
    }
    this.loading = false;
  }

  close() {
    this.currentPassword = '';
    this.currentPasswordProgress = 0;
    this.newPassword = '';
    this.newPasswordProgress = 0;
    this.newPasswordConfirm = '';
    this.newPasswordConfirmProgress = 0;
    this.showCurrentPassword = false;
    this.showNewPassword = false;
    this.showNewPasswordConfirm = false;
    this.showNewPasswordConfirm = false;

    this.success = false;
  }
}
