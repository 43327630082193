var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"red-juranews relevation-0 rounded-0 text-center",attrs:{"dark":"","width":"130"}},'v-btn',attrs,false),on),[_vm._v("ACCEDI")])]}}]),model:{value:(_vm.showLogin),callback:function ($$v) {_vm.showLogin=$$v},expression:"showLogin"}},[_c('jn-progress',{attrs:{"loading":_vm.loading}}),_c('v-card',{staticClass:"grey darken-1 pt-6 pb-3"},[_c('v-card-text',[(!_vm.showResetPassword)?_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"append-icon":'mdi-email',"label":"Il tuo indirizzo e-mail","type":"email","error-messages":errors,"dark":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1),_c('v-col',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"label":"Password","type":_vm.showPassword ? 'text' : 'password',"error-messages":errors,"loading":"","dark":""},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"value":_vm.progressPassword,"color":_vm.colorPassword,"absolute":"","height":"6"}})]},proxy:true}],null,true),model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}},'v-text-field',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v("La password deve contenere almeno 8 caratteri alfanumerici, di cui almeno uno maiuscolo e un numero.")])])]}}],null,true)})],1)],1),_c('v-row',[_c('v-btn',{staticClass:"grey elevation-0 rounded-0",attrs:{"dark":"","text":""},on:{"click":_vm.cancel}},[_vm._v(" ANNULLA ")]),_c('v-spacer'),_c('jn-change-password',{attrs:{"type":"link"}}),_c('v-btn',{staticClass:"red-juranews elevation-0 rounded-0",attrs:{"dark":"","disabled":invalid || !_vm.passwordOk},on:{"click":_vm.login}},[_vm._v(" ACCEDI ")])],1)],1)]}}],null,false,965154399)}):_vm._e(),(_vm.showResetPassword)?_c('jn-reset-password',{attrs:{"username":_vm.username}}):_vm._e()],1)],1),(_vm.resetted)?_c('v-card',{staticClass:"grey darken-1 pb-10 text-center",attrs:{"elevation":"0"}},[_c('v-card-text',{staticClass:"text align red--text-juranews text-h6",attrs:{"dark":""}},[_vm._v("Password modificata con successo!")]),_c('v-btn',{staticClass:"red-juranews white--text",attrs:{"text":""},on:{"click":_vm.closeReset}},[_vm._v(" CHIUDI ")])],1):_vm._e(),_c('v-snackbar',{attrs:{"timeout":_vm.alertTimeout,"centered":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({staticClass:"red-juranews white--text",attrs:{"text":""},on:{"click":function($event){_vm.alert = false}}},'v-btn',attrs,false),[_vm._v(" CHIUDI ")])]}}]),model:{value:(_vm.alert),callback:function ($$v) {_vm.alert=$$v},expression:"alert"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.alertMessage)}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }