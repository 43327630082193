import moment from 'moment';
import { asBlob } from 'html-docx-js-typescript';

export class Utils {
  static async timeout(millis: number) {
    return new Promise((resolve) => setTimeout(resolve, millis));
  }

  static checkProfile(profiles: any, profile: string) {
    console.log("profile: " + profile)
    for (const p of profiles) {
      if (p.profile === profile) {
        return true;
      }
    }
    return false;
  }

  static b64DecodeUnicode(text: string): string {
    return decodeURIComponent(
      Array.prototype.map
        .call(atob(text), function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
  }

  static sort(prop: any, type: any) {
    return function (a: any, b: any) {
      if (type && type == 'asc') {
        if (a[prop] > b[prop]) {
          return 1;
        } else if (a[prop] < b[prop]) {
          return -1;
        }
      } else if (!type || type == 'desc') {
        if (a[prop] < b[prop]) {
          return 1;
        } else if (a[prop] > b[prop]) {
          return -1;
        }
      }
      return 0;
    };
  }

  static getImage(source: any): string {
    let imageSrc = source.image;
    if (!imageSrc || imageSrc == '') {
      imageSrc =
        process.env.VUE_APP_JURANEWS_BACKOFFICE_URL +
        '/images/placeholder/image-placeholder.jpeg';
    } else {
      imageSrc = process.env.VUE_APP_JURANEWS_BACKOFFICE_URL + '/' + imageSrc;
    }
    return imageSrc;
  }

  static htmlToSave(divName: string) {
    const contentToPrint = document.getElementById(divName);
    let stylesHtml = '';
    for (const node of [
      ...document.querySelectorAll('link[rel="stylesheet"], style'),
    ]) {
      stylesHtml += node.outerHTML;
    }
    return (
      '<html><head>' +
      stylesHtml +
      '</head><body><img class="center" style="margin-top: 15px;" src="/img/logo.png" height="80" /><br/><hr class="solid">' +
      contentToPrint!.innerHTML +
      '</div></body></html>'
    );
  }

  static print(divName: string) {
    const contentToPrint = document.getElementById(divName)?.cloneNode(true);
    let elementToRemove = (contentToPrint as HTMLElement).querySelector('.NotPrintable');
    while (elementToRemove) {
      elementToRemove.remove();
      elementToRemove = (contentToPrint as HTMLElement).querySelector('.NotPrintable');
    }
    let stylesHtml = '';
    for (const node of [
      ...document.querySelectorAll('link[rel="stylesheet"], style'),
    ]) {
      stylesHtml += node.outerHTML;
    }

    if (contentToPrint) {
      const winPrint = window.open(
        '',
        '',
        'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0'
      );

      if (winPrint) {
        winPrint.document.write(`<!DOCTYPE html>
<html>
  <head>
    ${stylesHtml}
    <script>
      function printDocument() {
          var content = document.getElementById('content');

          console.log(content)
          var printButton = document.querySelector('button');
          printButton.style.display = 'none';

          window.print();

          printButton.style.display = 'block';
      }
  </script>
  </head>
  <body>
    <button onclick="printDocument()" type="button" style="margin: 10px 10px" class="ml-3 mt-3 red-juranews relevation-0 rounded-0 text-center v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default" style="width: 130px;"><span class="v-btn__content">Stampa</span></button>
    <div id="content">
      <img class="center" style="margin-top: 15px;" src="/img/logo.png" height="80" />
      <br/>
      <hr class="solid">
      ${(contentToPrint as HTMLElement).innerHTML}
    </div>
  </body>
</html>`);
        winPrint.document.close();
        winPrint.focus();
      }
    }
  }
}
